/* open-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-regular.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-600 - latin-ext_latin */
  @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-600.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-700 - latin-ext_latin */
  @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-700.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-800 - latin-ext_latin */
  @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-800.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-italic - latin-ext_latin */
  @font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* open-sans-600italic - latin-ext_latin */
  @font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local(''),
          url('../fonts/open-sans-v34-latin-ext_latin-600italic.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
